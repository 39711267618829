import React from 'react';
import './footer.css';
import logo from '../../assets/andrewlogo.png'; // Import the logo

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        
        <p>&copy; {new Date().getFullYear()} Andrew Escudero</p>
        <a href="#">
          <img src={logo} alt="logo" className="footer-logo" /> {/* Use the logo */}
        </a>
        <div className="footer-links">
          <a href="https://www.andrewescudero.com/" target="_blank" rel="noopener noreferrer" >Artwork</a>
          <a href="https://www.instagram.com/andrew_escudero_/" target="_blank" rel="noopener noreferrer" >Instagram</a>
          <a href="https://twitter.com/Drew_Dev_Artist" target="_blank" rel="noopener noreferrer" >X</a>
          <a href="https://www.andrewescudero/contact" target="_blank" rel="noopener noreferrer" >Contact</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;